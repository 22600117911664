<template>
  <div class="mt-1">
    <div class="title_resultsPatsnt">
      <div class="title_resultsPatsnt_text">
        {{ $t("message.all_service_patient") }}
      </div>
      <el-button
        v-if="lastHistory.is_ended != 0"
        disabled
        v-can="'hospitalizationServices.create'"
        icon="el-icon-edit"
        @click="drawer.create.status = true"
        type="primary"
        round
        >{{ $t("message.add_service") }}
      </el-button>
      <el-button
        v-else
        v-can="'hospitalizationServices.create'"
        icon="el-icon-edit"
        @click="drawer.create.status = true"
        type="primary"
        round
        >{{ $t("message.add_service") }}
      </el-button>
    </div>

    <div class="table_servis_list" v-if="list.length != 0">
      <table class="table mt-4" v-loading="loadingData">
        <thead>
          <tr>
            <th class="wt5">{{ $t("message.pp") }}</th>
            <th>{{ $t("message.name") }}</th>
            <th class="wt7">{{ $t("message.quantity_short") }}</th>
            <th class="wt10">{{ $t("message.date_0") }}</th>
            <th class="wt10">{{ $t("message.price2") }}</th>
            <th class="wt7">{{ $t("message.discount_percent") }}</th>
            <th class="wt10">{{ $t("message.discount_price") }}</th>
            <th class="wt10">{{ $t("message.amount") }}</th>
            <th class="wt10">{{ $t("message.settings") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(ser, index) in list"
            :key="'order-create-in-header' + index"
          >
            <td>{{ index + 1 }}</td>
            <td>
              {{ ser.name }}
            </td>
            <td>
              {{ ser.count }}
            </td>
            <td>
              {{ ser.created_at }}
            </td>
            <td>
              {{ ser.price | formatMoney }}
            </td>
            <td>
              {{ ser.discount }}
            </td>
            <td>
              {{ (ser.price * ser.count - ser.total_price) | formatMoney }}
            </td>
            <td>
              {{ ser.total_price | formatMoney }}
            </td>
            <td class="d-flex" v-if="lastHistory.is_ended != 0">
              <el-button
                disabled
                icon="el-icon-edit"
                @click="edit(ser)"
                type="primary"
                round
                >{{ $t("message.update") }}
              </el-button>
              <el-button
                type="danger"
                disabled
                icon="el-icon-delete"
                circle
                @click="destroy(ser)"
              >
              </el-button>
            </td>

            <td class="d-flex" v-else>
              <el-button
                icon="el-icon-edit"
                @click="edit(ser)"
                type="primary"
                round
                >{{ $t("message.update") }}
              </el-button>
              <el-button
                type="danger"
                icon="el-icon-delete"
                circle
                @click="destroy(ser)"
              >
              </el-button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <el-drawer
      size="90%"
      :visible.sync="drawer.create.status"
      :ref="drawer.create.name"
      :before-close="beforeClose"
      @opened="drawerOpened(drawer.create.component)"
      @closed="drawerClosed(drawer.create.component)"
    >
      <crm-create
        :ref="drawer.create.component"
        :drawer-name="drawer.create.name"
      ></crm-create>
    </el-drawer>

    <el-drawer
      size="85%"
      :visible.sync="drawer.update.status"
      :with-header="false"
      :ref="drawer.update.name"
      :before-close="beforeClose"
      @opened="drawerOpened(drawer.update.component)"
      @closed="drawerClosed(drawer.update.component)"
    >
      <update-form
        :drawer-name="drawer.update.name"
        :ref="drawer.update.component"
        :selected="selectedModel"
      />
    </el-drawer>
  </div>
</template>
<script>
import CrmCreate from "./components/addPatientService";
import UpdateForm from "./components/UpdatePatientService";
import list from "@/utils/mixins/list";
import drawer from "@/utils/mixins/drawer";
import { i18n } from "@/utils/i18n";

import { mapGetters, mapActions } from "vuex";
export default {
  mixins: [drawer, list],
  name: "patientService",
  data() {
    return {
      loadingData: false,
      isItemsModelVisible: false,
      isLoadingItems: false,
      selectedModel: null,
      drawer: {
        create: {
          name: "create",
          component: "componentCreate",
          status: false,
        },
        update: {
          name: "update",
          component: "componentUpdate",
          status: false,
        },
      },
    };
  },
  components: {
    UpdateForm,
    CrmCreate,
  },
  computed: {
    getId() {
      return this.$route.params.id;
    },
    ...mapGetters({
      activeCode: "patients/activeCode",
      list: "hospitalizationServices/list",
      columns: "hospitalizationServices/columns",
      pagination: "hospitalizationServices/pagination",
      filter: "hospitalizationServices/filter",
      sort: "hospitalizationServices/sort",
      lastHistory: "patientHistories/model",
    }),
    actions: function () {
      return ["edit", "delete"];
    },
  },
  mounted() {
    if (this.lastHistory.id === "") {
      this.getLastHistory({id: this.getId});
    }
  },
  watch: {
    lastHistory: {
      handler: function (val, oldVal) {
        this.editModel({
          patient_id: val.patient_id,
          id: val.id,
        })
          .then((res) => {})
          .catch((err) => {
            this.$notify({
              title: 'Ошибка',
              type: "error",
              offset: 130,
              message: err.error.message
            });
          });
      },
    },
  },
  methods: {
    ...mapActions({
      updateList: "hospitalizationServices/index",
      updateSort: "hospitalizationServices/updateSort",
      updateFilter: "hospitalizationServices/updateFilter",
      updateColumn: "hospitalizationServices/updateColumn",
      updatePagination: "hospitalizationServices/updatePagination",
      editModel: "hospitalizationServices/indexitems",
      empty: "hospitalizationServices/empty",
      delete: "hospitalizationServices/destroy",
      refreshData: "hospitalizationServices/refreshData",
      getLastHistory: "patientHistories/show",
    }),
    fetchData() {
      this.loadingData = true;
      var vm = this;
      this.editModel({
        patient_id: vm.getId,
        id: this.lastHistory.id,
      })
        .then((res) => {
          this.loadingData = false;
        })
        .catch((err) => {
          this.loadingData = false;
          this.$notify({
            title: 'Ошибка',
            type: "error",
            offset: 130,
            message: err.error.message
          });
        });
    },
    edit(model) {
      this.selectedModel = model;
      this.drawer.update.status = true;
    },
    handleCommand(command) {
      if (command === "delete") {
      }
    },
    destroy(model) {
      this.$confirm(i18n.t("message.do_you_want"), i18n.t("message.warning"), {
        confirmButtonText: i18n.t("message.yes"),
        cancelButtonText: i18n.t("message.cancel"),
        type: "warning",
      })
        .then(() => {
          this.delete(model.id)
            .then((res) => {
              this.$notify({
            title: 'Успешно',
            type: "success",
            offset: 130,
            message: res.message
          });
              this.refreshData();
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch(() => {
          this.$message({
            type: "warning",
            message: i18n.t("message.cancel"),
          });
        });
    },
    beforeClose(done) {
      this.empty();
      this.refresh();
      done();
    },
    onCloseDialog(done) {
      done();
      this.empty();
      this.isLoadingItems = false;
    },
    refresh() {
      this.refreshData()
      this.filterForm = JSON.parse(JSON.stringify(this.filter));
    },
    async show(model) {
      await this.showModel(model.id)
        .then((res) => {
          this.drawerShow = true;
        })
        .catch((err) => {});
    },
    emptyModel() {
      this.empty();
    },
  },
};
</script>
<style lang="scss">
.table_servis_list .wt5 {
  width: 4%;
}

.table_servis_list .wt7 {
  width: 100px;
}

.table_servis_list .wt10 {
  width: 12%;
}
</style>

import CreatePatientService from '@/views/scheduler/create-patient-service';
import CreateDepartment from '@/views/scheduler/add-department';
import {
    mapGetters,
    mapActions,
    createLogger
} from "vuex";
import _ from 'lodash';
import { i18n } from "@/utils/i18n";

export default {
    props: ['drawer', 'open'],
    components: {
        CreatePatientService,
        CreateDepartment
    },
    data() {
        return {
            activeTab: 'first',
            waitingPrint: false,
            waiting: false,
            search: '',
            yes_user: false,
            user: null,
            form: {},
            query: {
                relation: true
            },
            patientServiceId: '',
            loadingData: false,
            drawerPartner: false,
            drawerPatientService: false,
            latestServices: [],
            discountPatients: [],
            patientDiscount: false,
            items: []
        }
    },
    computed: {
        ...mapGetters({
            list: 'services/selected_list',
            services: 'services/relation_list',
            servicesSelected: 'services/selected_relation_list',
            modelService: 'services/model',
            model: 'orders/model',
            express: "expresses/list",
            money: 'money',
        }),
        totalAmount() {
            if (_.size(this.items)) {
                if (this.patientDiscount) {
                    return this.items.reduce((a, b) => a + (b.count * this.expressPrice(b.is_express) * (b.price - ((b.price * this.discountPatients[0].discount) / 100)) || 0), 0)
                } else {
                    let self = this;
                    return this.items.reduce(function (a, b) {
                        if ((b.discount_price !== 0)) {
                            return a + (b.count * self.expressPrice(b.is_express) * (b.price - ((b.price * b.discount_price) / 100)) || 0)
                        } else {
                            return a + ((b.count * self.expressPrice(b.is_express) * b.price - b.price_of_discount) || 0)
                        }
                    }, 0);
                }
            }
        },
        totalDiscount() {
            if (_.size(this.items)) {
                if (this.patientDiscount) {
                    return this.items.reduce((a, b) => a + (b.price * this.discountPatients[0].discount * b.count / 100 || 0), 0)
                } else {
                    return this.items.reduce(function (a, b) {
                        if (b.discount_price !== 0) {
                            return a + (b.price * b.discount_price * b.count / 100 || 0)
                        } else {
                            return a + (b.price_of_discount || 0)
                        }
                    }, 0);
                }
            }
        },
    },
    mounted() {
        this.loadingData = true;
        var order_query = {
            column: 'name',
            order: 'asc'
        }
        if (this.services && this.services.length === 0) this.servicesRealtionList(this.query)
        if (this.servicesSelected && this.servicesSelected.length === 0) this.servicesSelectedRealtionList({
            selected_relation: true,
        }).then((res) => {
            this.loadingData = false;
        }).catch((err) => {
            this.loadingData = false;
        })
    },
    methods: {
        ...mapActions({
            updateSelectedListItem: 'services/updateSelectedItem',
            servicesRealtionList: 'services/relationList',
            servicesSelectedRealtionList: 'services/selectedRelationList',
            saveList: 'services/setlist',
            emptyList: 'services/emptyList',
            removeSelectedList: 'services/removeSelectedList',
            loadPatientLastOrderServices: 'patients/loadPatientLastOrderServices',
        }),
        opened() {
        },
        ifValidationError(err) {
            if (err.code == 422) {
                let errors = ''
                for (var k in err.error[0]) {
                    if (err.error[0].hasOwnProperty(k)) {
                        errors = errors + err.error[0][k][0] + '\n';
                    }
                }
                this.$notify({
                    title: 'Ошибка ',
                    type: "error",
                    position: "bottom right",
                    message: errors
                });
            } else {
                this.$notify({
                    title: 'Ошибка',
                    type: "error",
                    offset: 130,
                    message: err.error.message
                });
            }
        },
        expressPrice(is_express) {
            if (is_express) {
                return 1 + (this.express.length ? this.express[0].percent : 50) / 100;
            } else {
                return 1;
            }
        },
        noUser(yes = false) {
            this.yes_user = yes;
        },
        parent() {
            return this.$parent.$parent
        },

        editRow(row, event, key) {
            let data = {
                id: row.id,
                value: event,
                position: row.position,
                key: key
            }
            this.updateSelectedListItem(data)
                .then(res => {
                    this.items = JSON.parse(JSON.stringify(this.list));
                }).catch(err => { })
        },
        deleteRow(scope) {
            this.$confirm(
                i18n.t("message.delete_notification_confirm"),
                i18n.t("message.warning"),
                {
                    confirmButtonText: i18n.t("message.yes"),
                    cancelButtonText: i18n.t("message.cancel"),
                    type: "warning",
                }
            )
                .then(() => {
                    this.removeSelectedList(scope)
                        .then(res => {
                            this.items = JSON.parse(JSON.stringify(this.list));
                            this.$message({
                                type: 'success',
                                message: i18n.t("message.delete_success"),
                            });
                        }).catch(err => {

                        });
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: i18n.t("message.delete_calcel"),
                    });
                });
        },
        close() {
            this.empty();
            this.parent().closeChildDrawer(this.drawer);
        },
        closeDrawerPatientService(obj) {
            if (obj.drawer) {
                this[obj.drawer] = false
            }
        },
        edit(model) {
            this.user = JSON.parse(JSON.stringify(model));
            this.noUser(true)

            this.patientDiscount = false;
            this.discountPatients = [];
            this.latestServices = [];
            this.loadPatientLastOrderServices(model.id)
                .then(res => {
                    this.latestServices = res.data;
                })
                .catch(err => {
                    console.log(err);
                });
            this.discountPatient(model.id)
                .then(res => {
                    this.discountPatients = res.data;
                    if (res.data.length && res.data[0].status) this.patientDiscount = true
                }).catch(err => { });

        },
        itemsChanged(val) {
            var items = JSON.parse(JSON.stringify(val));
            for (var item in items) {
                if (items.hasOwnProperty(item)) {
                    this.saveList([JSON.parse(JSON.stringify(items[item]))])
                        .then(res => {
                            this.items = JSON.parse(JSON.stringify(this.list));
                            this.patientServiceId = "";
                        }).catch(err => { });
                }
            }
        },
        addService() {
            let vm = this;
            let service = this.servicesSelected.find(function (item) {
                return item.id == vm.patientServiceId;
            })
            if (service) {
                this.saveList([JSON.parse(JSON.stringify(service))])
                    .then(res => {
                        this.items = JSON.parse(JSON.stringify(this.list));
                        this.patientServiceId = "";
                    }).catch(err => { });
            }
        },
        changeWaiting(def = false) {
            this.waiting = def;
        },
        getChildrens: function (children) {
            if (children) return children.filter((item) => {
                return item.is_service == true
            });
            return [];
        },
    },
}